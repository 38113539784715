import React from "react";
import nodish from "../assets/images/others/nodish.png";
const NoDishes=()=>
{

    return(
        <>
        <div className="py-4">
        <div className="text-center text-danger"><img src={nodish} height={200} width={200} alt="no order img"/></div>     
        <h5 className="fw-400 mt-4 text-pink text-center">Dishes not available</h5>
        </div>
        </>
    )
}
export default NoDishes