import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BiCurrentLocation } from "react-icons/bi";
import { Calls, errorToast, successToast } from "../utils/call";
import { api } from "../urls";
import Login from "./login";
import { LocationContext } from "../provider-data/UserLocation";
const Location = ({ show, setShow }) => {
  const {latLong,address,getCurrentAddress,getLiveCurrentAddress} = useContext(LocationContext)
  const [locationList, setLocationList] = useState([]);
  const [loginShow,setLoginShow] = useState(false)
  const [currentAddressType,setCurrentAddressType] =useState(localStorage.getItem("currentAddressType"))

  useEffect(() => {
    fetchLocation();
  }, []);

  const fetchLocation = async () => {
    const res = await Calls.requestGet(api.getUserAddress);
    if (res.data) {
      setLocationList(res.data.data);
    }
  };
  const updateAddress =async (addressType) => {
    const token = localStorage.getItem("token")
    if(token){
      let reqBody = {
          ...latLong,
          addressType,
          address
      }
          const res = await Calls.requestPost(api.updateUserAddress,reqBody)
          if(res?.data){
       
            successToast(res)
            fetchLocation()
          }else{
            errorToast(res)
          }
    }else{
      setLoginShow(true)
    }
    
  };
 const locationHandler  = (type,lat,long)=>{
  localStorage.setItem("currentAddressType",type)
  localStorage.setItem("lat",lat)
  localStorage.setItem("long",long)
  setCurrentAddressType(type)
  setShow(false)
  getCurrentAddress(lat,long)
     
 }
 const currentLatLong = ()=>{
  localStorage.removeItem("currentAddressType")
  localStorage.removeItem("lat")
  localStorage.removeItem("long")
  setShow(false)
  getLiveCurrentAddress()
  
 }

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="location "
        size="sm"
      >
        <div>
          <Modal.Body>
            <div className="row justify-content-center location-modal">
              <div className="col-12 ">
                {/* <Form className="d-flex px-0" id="search-wrapper">
                <FaLocationDot className="search-icon" size={20} />
                <Form.Control
                  id="search"
                  type="search"
                  placeholder="Select your location....."
                  className=""
                  aria-label="Search"
                
                />
                <FaSearch className="search-button" size={20} />

              </Form> */}

                <div className="bg-white mt-3 mb-4 current-loc" onClick={currentLatLong}>
                  <p className=" mb-0 text-center">
                    <span className="me-2">
                      <BiCurrentLocation className="fs-4" />
                    </span>{" "}
                    <span className="fs-13 fw-600">Use current location </span>
                  </p>
                </div>
                <h6 className="fw-600 text-bluec my-3">ADDRESSES </h6>
                <div class="d-flex flex-row bd-highlight mb-3">
                  <div class="p-2  cPointer fw-bold" onClick={()=>updateAddress("home")}>Home</div>
                  <div class="p-2  cPointer fw-bold" onClick={()=>updateAddress("office")}>Office</div>
                  <div class="p-2  cPointer fw-bold" onClick={()=>updateAddress("other")}>Other</div>
                </div>

                <div className="billing-details">
                  <div className="billing-address">
                    {locationList?.length > 0 &&
                      locationList.map((item,i) => {
                        return (
                          <div className="billing-address-detail bg-white" key={i}>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                //  value={currentAddressType}
                                 checked={item?.addressType===currentAddressType}
                                 onChange={()=>locationHandler(item?.addressType,item.lat,item.long)}
                                name="currentAddressType"
                                id="flexRadioDefault1"
                              />
                              <label
                                className="form-check-label fw-bold"
                                for="flexRadioDefault1"
                              >
                                {item?.addressType}
                              </label>
                            </div>

                            <div className="billing-fulladdress ps-4 fs-12 text-justify">
                              <p>
                              {item.address}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Login show={loginShow} setShow={setLoginShow} />

    </>
  );
};

export default Location;
